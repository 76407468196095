export default defineNuxtPlugin(async nuxtApp => {
  const Inputmask = (await import('inputmask')).default

  nuxtApp.vueApp.directive('inputmask', {
    mounted(el, binding) {
      const element = el.tagName !== 'input' ? el.getElementsByTagName('input')[0] : el

      new Inputmask({
        showMaskOnHover: false,
        showMaskOnFocus: false,
        ...binding.value,
      }).mask(element)
    },
  })
})
