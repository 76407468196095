import { capitalize } from 'vue'

export default defineNuxtPlugin(() => {
  /* Resolves the storyblok component */
  // It is needed so that the components are rendered on the server instead of the client.
  function resolveStoryBlokComponent(story, lazy = false) {
    if (!story) {
      return null
    }
    const name = lazy ? `lazy${capitalize(story?.component || story?.content?.component)}` : story?.component || story?.content?.component

    return resolveComponent(name)
  }

  return {
    provide: {
      resolveStoryBlokComponent,
    },
  }
})
