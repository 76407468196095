import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import themes_45global from "/app/node_modules/@unbiased/uk-nuxt-core/app/middleware/themes.global.ts";
import disable_45vue_45transitions_45global from "/app/app/middleware/disable-vue-transitions.global.ts";
import trailing_45slash_45global from "/app/app/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  themes_45global,
  disable_45vue_45transitions_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}