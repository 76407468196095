import { useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const wt = useScript({"key":"wt","src":"https://c.webtrends-optimize.com/acs/accounts/75c36497-40b5-4a9e-a095-e3fe609b9351/js/wt.js"}, { ...{"trigger":"server"}, use: () => ({ wt: window.wt }) })
    return { provide: { $scripts: { wt } } }
  }
})