import { datadogRum } from '@datadog/browser-rum'

export default defineNuxtPlugin(nuxtApp => {
  const preview = nuxtApp?.$preview

  if (preview || import.meta.dev) {
    return
  }

  datadogRum.init({
    applicationId: '7f0ab94f-9207-4a20-ad76-301d6b78aa0c',
    clientToken: 'pubff1c303ed3fdfea03125a1fcda68c10f',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.eu',
    service: 'consumer-www',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 1,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
})
